import {tns} from "tiny-slider/src/tiny-slider";

tns({
    "container": ".clients-say",
    "items": 1,
    "axis": "vertical",
    "swipeAngle": false,
    "speed": 300,
    autoplay: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
})

tns({
    "container": ".clients-logo",
    "items": 3,
    "swipeAngle": false,
    "speed": 300,
    autoplay: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
})
